<template>
  <div class="chart"></div>
</template>

<script>
  import echart from "echarts";

  export default {
    props: {
      // 控件所需数据：{name:'图表名称',x:['1','2'],data:[{name:'legend',data:[1,2]}]}
      data: {
        type: Object,
        default: () => ({
          name: '',
          data: [],
          x: [],
        })
      },
      option: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        index: 0,
        chart: null,
      };
    },
    mounted() {
      this.chart = echart.init(this.$el);
      this.$on('resize', () => {
        this.chart.resize();
      });
    },
    beforeDestroy: function () {
      this.chart.dispose();
    },
    watch: {
      data: {
        deep: true,
        immediate: true,
        handler: function (d = {x: [], data: [], name: ''}) {
          this.$nextTick(() => this.chart.setOption(Object.assign({
            color: ["#9287e7", "#60acfc", "#5bc49f", "#ff7c7c", "#feb64d",],
            legend: {
              left: 'center',
              top: '0'
            },
            // series: d.data,
            series: d.data.map(item => ({
              data: item.value,
              name: item.name,
              smooth: true,
              type: 'line',
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 1//设置线条粗细
                  }
                }
              }
            })),
            tooltip: {
              trigger: 'axis'
            },
            dataZoom: [
              {
                type: 'inside',
                start: 0,
                end: 10
              },
              {
                start: 0,
                end: 10,
                handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                handleSize: '80%',
                handleStyle: {
                  color: '#fff',
                  shadowBlur: 3,
                  shadowColor: 'rgba(0, 0, 0, 0.6)',
                  shadowOffsetX: 2,
                  shadowOffsetY: 2
                }
              }
            ],
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: d.x,
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#666666'
                }
              },
              axisLine: {
                lineStyle: {
                  color: "#1A245064",
                  width: 1
                }
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: '#fafafa'
                }
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#666666'
                }
              },
              axisLine: {
                lineStyle: {
                  color: "#1A245064",
                  width: 1
                }
              }
            },
            toolbox: {
              show: true,
              feature: {
                mark: {show: true},
                dataView: {show: true, readOnly: false},
                magicType: {show: true, type: ['line', 'bar']},
                restore: {show: true},
                saveAsImage: {show: true}
              },
              top: "20px"
            },
          }, this.option), true));
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .chart {
    width: 100%;
    height: 100%;
  }
</style>
