<template>
  <div class="pump-chart ly-wrap">
    <div class="currency-title"><span class="back" @click="goBack"></span>历史数据-趋势图</div>

    <div class="chart-box">
      <history-line :data="lineOption"/>
    </div>

  </div>
</template>

<script>

import storage from "@/store/storage";
import HistoryLine from "@/components/chart/HistoryLine.vue";
import {getAction} from "../../api/manage";

export default {
  name: 'PumpChart',
  components: {HistoryLine},
  computed: {
    lineOption() {
      return {
        x: this.chartData.datax,
        name: "历史数据",
        data: this.chartData.models,
      };
    },
  },
  data() {
    return {
      storageQuery: {},
      chartData: {datax: [], models: []}
    }
  },
  mounted() {
  },
  created() {
    this.getParams()
  },
  methods: {
    getParams() {
      this.storageQuery = storage.getObj('condition')
      this.loadData()
    },
    loadData() {
      const parameter = {
        variableId: this.storageQuery.variableId.join(','),
        start: this.storageQuery.start,
        end: this.storageQuery.end
      }
      getAction('/water/historyData/queryMoreByVo',parameter).then(res => {
        this.chartData = res.data.result
      })
    },
    goBack() {
      this.$router.go(-1)
    },
  },
  destroyed: function () {
  },
}
</script>

<style scoped lang="less">
.chart-box {
  height: 4rem;
  padding: 0.5rem 0.4rem 0.5rem;
}
</style>

